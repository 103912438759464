

















































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ReportItem from "@/app/components/reports/ReportItem.vue";
import { fetchYearsTillDate } from "@/utils/helpers";
import PreviewUserModal from "@/app/components/modals/PreviewUser.vue";
import { HOME } from "@/app/routes/endpoints";
import ReassignReviewerModal from "@/app/components/modals/ReassignReviewerModal.vue";

export default Vue.extend({
    components: { ReportItem, PreviewUserModal, ReassignReviewerModal },
    data() {
        const selectedYear = new Date().getFullYear();

        return {
            isLoading: false,
            activeTab: "all",
            showYearsToShow: false,
            search: "",
            selectedReportStatus: "all",
            pageNumber: 1,
            pageSize: 10,
            searchResults: [],
            reports: [] as any[],
            isFetchingMoreReports: false,
            selectedYear,
            years: fetchYearsTillDate(2022),
            totalCount: 0,
            reportsMetaData: {
                reviewedCount: 0,
                unReviewedCount: 0,
                certificationIssuedCount: 0,
                certificationDeniedCount: 0,
                regulatorApprovalInProgressCount: 0,
                submittedCount: 0,
            },
            currentReportId: "",
            currentlyViewedUser: null,
            selectedReport: null,
            haveFinishedFetching: false,
        };
    },
    computed: {
        ...mapGetters({ allUsers: "users/allUsers", profileData: "auth/getUserData" }),
        hasMoreReportsToFetch(): boolean {
            const selectedStatus = this.statusFilters.find((status) => status.key === this.selectedReportStatus);
            if (selectedStatus) {
                return this.pageNumber * this.pageSize < Math.ceil(selectedStatus.count);
            }
            return false;
        },
        statusFilters(): Array<{ key: string; value: string; count: number }> {
            return [
                { key: "all", value: "submitted", count: this.reportsMetaData.submittedCount },
                { key: "Pending", value: "unreviewed", count: this.reportsMetaData.unReviewedCount },
                { key: "Reviewed", value: "reviewed", count: this.reportsMetaData.reviewedCount },
                { key: "CertificationIssued", value: "certification issued", count: this.reportsMetaData.certificationIssuedCount },
                { key: "CertificationDenied", value: "certification denied", count: this.reportsMetaData.certificationDeniedCount },
            ];
        },
        tabs(): any {
            const tabs = [{ key: "all", value: "All Reports" }];
            if (this.profileData.roles.includes("Reviewer")) tabs.push({ key: "review", value: "My Desk - Review" });
            if (this.profileData.roles.includes("Certifier")) tabs.push({ key: "certify", value: "My Desk - Certify" });
            return tabs;
        },
    },
    methods: {
        ...mapActions("reports", ["fetchReportList", "fetchReportListOnUserDesk", "startReview"]),
        async fetchReports() {
            this.isLoading = true;
            try {
                const { year, reportStatus, tab } = this.$route.query;
                const variables = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    year: year || this.selectedYear,
                    ...(reportStatus && reportStatus !== "all" && { reportStatus }),
                    ...(this.search !== "" && { search: this.search }),
                    ...(tab && (tab === "review" || tab === "certify") && { tab }),
                };
                let response;
                if (tab && (tab === "review" || tab === "certify")) {
                    response = await this.fetchReportListOnUserDesk(variables);
                } else {
                    response = await this.fetchReportList(variables);
                }
                if (this.pageNumber === 1) {
                    this.reports = response.items;
                } else {
                    this.reports = this.reports.concat(response.items);
                }
                this.totalCount = response.totalItems;
                this.reportsMetaData = response.itemsMetaData;
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        switchTab(tab: string) {
            if (tab === this.activeTab) return;
            this.activeTab = tab;
            this.$router.replace({ query: { ...this.$route.query, tab } });
        },
        fetchReportsPerYear(year: number) {
            this.showYearsToShow = false;
            if (year === this.selectedYear) return;
            this.selectedYear = year;
            this.$router.replace({ query: { ...this.$route.query, year: String(year) } });
        },
        setSelectedReportStatus(reportStatus: string) {
            if (reportStatus === this.selectedReportStatus) return;
            this.selectedReportStatus = reportStatus;
            this.$router.replace({ query: { ...this.$route.query, reportStatus } });
        },
        setSearchKeyword() {
            if (this.search === "") return;
            this.$router.replace({ query: { ...this.$route.query, search: this.search } });
        },
        clearSearchKeyword() {
            this.search = "";
            this.$router.replace({ query: { ...this.$route.query, search: undefined } });
        },
        async fetchMoreReports(isVisible: boolean) {
            if (isVisible && !this.isFetchingMoreReports && this.hasMoreReportsToFetch) {
                try {
                    this.pageNumber += 1;
                    this.isFetchingMoreReports = true;
                    await this.fetchReports();
                    this.isFetchingMoreReports = false;
                } catch (error) {
                    this.isFetchingMoreReports = false;
                }
            }
        },
        showReportOptions(reportId: string) {
            if (reportId === this.currentReportId) {
                this.currentReportId = "";
                return;
            }
            this.currentReportId = reportId;
        },
        showUserDetails(userId: string) {
            this.currentlyViewedUser = this.allUsers.find((user: any) => user.id === userId);
            this.$bvModal.show("modal-view-user");
        },
        async startReportReview(reportId: string) {
            this.currentReportId = "";
            this.$bvToast.toast("Starting report review...", { solid: true, variant: "info", title: "One moment" });
            try {
                const response = await this.startReview(reportId);
                if (response && response.message) {
                    this.$router.push({ name: HOME.VIEW_REPORT.NAME, params: { id: reportId } });
                }
            } catch {
                //
            }
        },
        async reassignReviewer(reportId: string) {
            this.currentReportId = "";
            this.selectedReport = this.reports.find((report) => report.id === reportId);
            await this.$nextTick();
            this.$bvModal.show("modal-reassign-reviewer");
        },

        changeReportReviewer(payload: any) {
            this.reports = this.reports.map((report) => {
                if (report.id === payload.id) {
                    return { ...report, currentDeskUser: { ...payload.currentDeskUser } };
                }
                return report;
            });
        },
    },
    async mounted() {
        const { year, reportStatus, search } = this.$route.query;
        if (year) this.selectedYear = Number(year);
        if (reportStatus) this.selectedReportStatus = reportStatus as string;
        if (search) this.search = search as string;
        await this.fetchReports();
        this.haveFinishedFetching = true;
    },
    watch: {
        "$route.query": {
            handler() {
                this.pageNumber = 1;
                this.fetchReports();
            },
        },
    },
});
